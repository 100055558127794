// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administracion-js": () => import("./../../../src/pages/administracion.js" /* webpackChunkName: "component---src-pages-administracion-js" */),
  "component---src-pages-avisodeprivacidad-js": () => import("./../../../src/pages/avisodeprivacidad.js" /* webpackChunkName: "component---src-pages-avisodeprivacidad-js" */),
  "component---src-pages-bienvenida-js": () => import("./../../../src/pages/bienvenida.js" /* webpackChunkName: "component---src-pages-bienvenida-js" */),
  "component---src-pages-coaches-dashboard-js": () => import("./../../../src/pages/coaches/dashboard.js" /* webpackChunkName: "component---src-pages-coaches-dashboard-js" */),
  "component---src-pages-coaches-index-js": () => import("./../../../src/pages/coaches/index.js" /* webpackChunkName: "component---src-pages-coaches-index-js" */),
  "component---src-pages-coaches-lugares-js": () => import("./../../../src/pages/coaches/lugares.js" /* webpackChunkName: "component---src-pages-coaches-lugares-js" */),
  "component---src-pages-colaboradores-dashboard-js": () => import("./../../../src/pages/colaboradores/dashboard.js" /* webpackChunkName: "component---src-pages-colaboradores-dashboard-js" */),
  "component---src-pages-colaboradores-index-js": () => import("./../../../src/pages/colaboradores/index.js" /* webpackChunkName: "component---src-pages-colaboradores-index-js" */),
  "component---src-pages-compra-js": () => import("./../../../src/pages/compra.js" /* webpackChunkName: "component---src-pages-compra-js" */),
  "component---src-pages-configuracion-js": () => import("./../../../src/pages/configuracion.js" /* webpackChunkName: "component---src-pages-configuracion-js" */),
  "component---src-pages-contacto-confirmacion-js": () => import("./../../../src/pages/contacto-confirmacion.js" /* webpackChunkName: "component---src-pages-contacto-confirmacion-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cycling-room-js": () => import("./../../../src/pages/cycling-room.js" /* webpackChunkName: "component---src-pages-cycling-room-js" */),
  "component---src-pages-encuesta-js": () => import("./../../../src/pages/encuesta.js" /* webpackChunkName: "component---src-pages-encuesta-js" */),
  "component---src-pages-flow-room-js": () => import("./../../../src/pages/flow-room.js" /* webpackChunkName: "component---src-pages-flow-room-js" */),
  "component---src-pages-frontdesk-asientos-flow-js": () => import("./../../../src/pages/frontdesk/asientos-flow.js" /* webpackChunkName: "component---src-pages-frontdesk-asientos-flow-js" */),
  "component---src-pages-frontdesk-asientos-js": () => import("./../../../src/pages/frontdesk/asientos.js" /* webpackChunkName: "component---src-pages-frontdesk-asientos-js" */),
  "component---src-pages-frontdesk-clientes-js": () => import("./../../../src/pages/frontdesk/clientes.js" /* webpackChunkName: "component---src-pages-frontdesk-clientes-js" */),
  "component---src-pages-frontdesk-cycling-room-js": () => import("./../../../src/pages/frontdesk/cycling-room.js" /* webpackChunkName: "component---src-pages-frontdesk-cycling-room-js" */),
  "component---src-pages-frontdesk-editar-clase-js": () => import("./../../../src/pages/frontdesk/editar-clase.js" /* webpackChunkName: "component---src-pages-frontdesk-editar-clase-js" */),
  "component---src-pages-frontdesk-editar-cliente-js": () => import("./../../../src/pages/frontdesk/editar-cliente.js" /* webpackChunkName: "component---src-pages-frontdesk-editar-cliente-js" */),
  "component---src-pages-frontdesk-editar-grupo-js": () => import("./../../../src/pages/frontdesk/editar-grupo.js" /* webpackChunkName: "component---src-pages-frontdesk-editar-grupo-js" */),
  "component---src-pages-frontdesk-flow-room-js": () => import("./../../../src/pages/frontdesk/flow-room.js" /* webpackChunkName: "component---src-pages-frontdesk-flow-room-js" */),
  "component---src-pages-frontdesk-grupos-js": () => import("./../../../src/pages/frontdesk/grupos.js" /* webpackChunkName: "component---src-pages-frontdesk-grupos-js" */),
  "component---src-pages-frontdesk-imagenes-home-js": () => import("./../../../src/pages/frontdesk/imagenes-home.js" /* webpackChunkName: "component---src-pages-frontdesk-imagenes-home-js" */),
  "component---src-pages-frontdesk-instructores-js": () => import("./../../../src/pages/frontdesk/instructores.js" /* webpackChunkName: "component---src-pages-frontdesk-instructores-js" */),
  "component---src-pages-frontdesk-lugares-flow-js": () => import("./../../../src/pages/frontdesk/lugares-flow.js" /* webpackChunkName: "component---src-pages-frontdesk-lugares-flow-js" */),
  "component---src-pages-frontdesk-lugares-js": () => import("./../../../src/pages/frontdesk/lugares.js" /* webpackChunkName: "component---src-pages-frontdesk-lugares-js" */),
  "component---src-pages-frontdesk-mis-clases-flow-js": () => import("./../../../src/pages/frontdesk/mis-clases-flow.js" /* webpackChunkName: "component---src-pages-frontdesk-mis-clases-flow-js" */),
  "component---src-pages-frontdesk-mis-clases-js": () => import("./../../../src/pages/frontdesk/mis-clases.js" /* webpackChunkName: "component---src-pages-frontdesk-mis-clases-js" */),
  "component---src-pages-frontdesk-nueva-clase-flow-js": () => import("./../../../src/pages/frontdesk/nueva-clase-flow.js" /* webpackChunkName: "component---src-pages-frontdesk-nueva-clase-flow-js" */),
  "component---src-pages-frontdesk-nueva-clase-js": () => import("./../../../src/pages/frontdesk/nueva-clase.js" /* webpackChunkName: "component---src-pages-frontdesk-nueva-clase-js" */),
  "component---src-pages-frontdesk-nuevo-instructor-js": () => import("./../../../src/pages/frontdesk/nuevo-instructor.js" /* webpackChunkName: "component---src-pages-frontdesk-nuevo-instructor-js" */),
  "component---src-pages-frontdesk-nuevo-paquete-js": () => import("./../../../src/pages/frontdesk/nuevo-paquete.js" /* webpackChunkName: "component---src-pages-frontdesk-nuevo-paquete-js" */),
  "component---src-pages-frontdesk-paquetes-js": () => import("./../../../src/pages/frontdesk/paquetes.js" /* webpackChunkName: "component---src-pages-frontdesk-paquetes-js" */),
  "component---src-pages-frontdesk-reservaciones-flow-js": () => import("./../../../src/pages/frontdesk/reservaciones-flow.js" /* webpackChunkName: "component---src-pages-frontdesk-reservaciones-flow-js" */),
  "component---src-pages-frontdesk-reservaciones-js": () => import("./../../../src/pages/frontdesk/reservaciones.js" /* webpackChunkName: "component---src-pages-frontdesk-reservaciones-js" */),
  "component---src-pages-frontdesk-transacciones-js": () => import("./../../../src/pages/frontdesk/transacciones.js" /* webpackChunkName: "component---src-pages-frontdesk-transacciones-js" */),
  "component---src-pages-frontdesk-ventas-js": () => import("./../../../src/pages/frontdesk/ventas.js" /* webpackChunkName: "component---src-pages-frontdesk-ventas-js" */),
  "component---src-pages-historial-js": () => import("./../../../src/pages/historial.js" /* webpackChunkName: "component---src-pages-historial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructores-js": () => import("./../../../src/pages/instructores.js" /* webpackChunkName: "component---src-pages-instructores-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-mapa-js": () => import("./../../../src/pages/mapa.js" /* webpackChunkName: "component---src-pages-mapa-js" */),
  "component---src-pages-mi-grupo-js": () => import("./../../../src/pages/mi-grupo.js" /* webpackChunkName: "component---src-pages-mi-grupo-js" */),
  "component---src-pages-mis-clases-flow-js": () => import("./../../../src/pages/mis-clases-flow.js" /* webpackChunkName: "component---src-pages-mis-clases-flow-js" */),
  "component---src-pages-mis-clases-js": () => import("./../../../src/pages/mis-clases.js" /* webpackChunkName: "component---src-pages-mis-clases-js" */),
  "component---src-pages-paquetes-js": () => import("./../../../src/pages/paquetes.js" /* webpackChunkName: "component---src-pages-paquetes-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-terminos-condiciones-js": () => import("./../../../src/pages/terminos&condiciones.js" /* webpackChunkName: "component---src-pages-terminos-condiciones-js" */),
  "component---src-pages-terminosycondiciones-js": () => import("./../../../src/pages/terminosycondiciones.js" /* webpackChunkName: "component---src-pages-terminosycondiciones-js" */)
}

